
div{
font-family: "Open Sans", sans-serif;

}

.button-right {
    float: right!important;
    margin-right: -15px;
}


.deviceWell{
  padding: 12px 10px 10px 20px;
  margin: 8px 8px 8px 8px;
  border-radius: 0px!important;
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:space-between;
  -webkit-transition:height 1s!important;
  transition:height 1s!important;


}

.deviceTitle{
  padding-top: 6px;
  font-size: 18px;
}

.deviceDiv{
    margin: 15px 15px 15px 15px;
}

.fabPos{
  float: right;
  margin-top: 20px;
  margin-right: 20px;
}

.alignCenter{
    padding-top:30px;
    padding-bottom:30px;
    text-align: center;
}
