.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.flexTitle{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:space-between;


}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainTitle{
  flexGrow: 1;
  font-family: "Arame";
  font-size: 18px;
}

body{
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  background-color: #e1e1e1;
}

.noTextDecor{
  text-decoration: none;
  color:white;

}
.padback{
  margin-left: 10px;
  margin-top: 10px;
}
